// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gt-tsx": () => import("./../../../src/pages/gt.tsx" /* webpackChunkName: "component---src-pages-gt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loja-tsx": () => import("./../../../src/pages/loja.tsx" /* webpackChunkName: "component---src-pages-loja-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */),
  "component---src-pages-sorteios-tsx": () => import("./../../../src/pages/sorteios.tsx" /* webpackChunkName: "component---src-pages-sorteios-tsx" */)
}

